import { graphql, useStaticQuery } from "gatsby";

const useExerciseLibrary = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query EXERCISE_LIBRARY_TITLES {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: {
            frontmatter: { templateKey: { eq: "exercise-library-post" } }
          }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges
    .map((x) => ({
      title: x.node.frontmatter.title,
      slug: x.node.fields.slug
        .replace("/exercise-library/", "")
        .replace("/", ""),
    }))
    .sort(function(a, b) {
      return a.title.localeCompare(b.title, undefined, {
        ignorePunctuation: true,
      });
    });
};

export default useExerciseLibrary;
