import * as React from "react";
import { format } from "date-fns";
import { useAuthUser } from "../Session";
import useExerciseLibrary from "../../hooks/useExerciseLibrary";
import useWarmupStretchVideos from "../../hooks/useWarmupStretchVideos";

const CONTENT_TEMPLATE = [
  {
    title: "Warm-up",
    content: [{ type: "text", value: "some example text" }],
  },
];

const INIT_STATE = {
  day: 1,
  week: format(new Date(), "yyyy-MM-dd"),
  content: CONTENT_TEMPLATE,
  equipment: "",
  title: "A Clever Title",
  video_title: "",
  video_url: "",
};

const WorkoutForm = ({
  initialState = INIT_STATE,
  onContentChange,
  onCloseForm,
}) => {
  const authUser = useAuthUser();
  const exerciseLibrary = useExerciseLibrary();
  const videoCollection = useWarmupStretchVideos();
  const [isLoading, setIsLoading] = React.useState(false);
  const [showAddExercise, setShowAddExercise] = React.useState(false);
  const [showAddVideo, setShowAddVideo] = React.useState(false);
  const [showAddLink, setShowAddLink] = React.useState(false);
  const [showAddSection, setShowAddSection] = React.useState(false);
  const [exerciseTitle, setExerciseTitle] = React.useState("");
  const [videoText, setVideoText] = React.useState("");
  const [linkText, setLinkText] = React.useState("");
  const [linkHref, setLinkHref] = React.useState("");
  const [sectionTitle, setSectionTitle] = React.useState("");
  const [exerciseReps, setExerciseReps] = React.useState("");
  const [exerciseTimer, setExerciseTimer] = React.useState("");
  const [exerciseRest, setExerciseRest] = React.useState("");
  const [selectedExercise, setSelectedExercise] = React.useState("none");
  const [selectedVideo, setSelectedVideo] = React.useState("none");
  const [value, setValue] = React.useState(initialState);
  const [tempContent, setTempContent] = React.useState(
    JSON.stringify(
      initialState ? initialState.content : CONTENT_TEMPLATE,
      undefined,
      4
    )
  );

  const EXERCISE_OPTIONS = React.useMemo(() => {
    if (exerciseLibrary) {
      return exerciseLibrary.map((item) => ({
        value: item.slug,
        label: item.title,
      }));
    }
    return [];
  }, [exerciseLibrary]);

  const VIDEO_OPTIONS = React.useMemo(() => {
    if (videoCollection) {
      return videoCollection.map((item) => ({
        value: item.slug,
        label: item.title,
      }));
    }
    return [];
  }, [videoCollection]);

  React.useEffect(() => {
    onContentChange(value);
  }, [value, onContentChange]);

  const handleChange = (event) => {
    if (event.target.name === "content") {
      setTempContent(event.target.value);
    } else {
      setValue({ ...value, [event.target.name]: event.target.value });
    }
  };

  const postWorkoutPayload = React.useCallback(async () => {
    try {
      setIsLoading(true);

      const payload = {
        ...value,
        day: Number(value.day),
        content: JSON.stringify(value.content),
      };
      const body = { payload, uid: authUser.uid };
      if (value.id) {
        body.workout_id = value.id;
        delete payload.id;
      }

      const result = await fetch("/.netlify/functions/update-workout", {
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await result.json();
      if (json.status === "succeeded") {
        // close the form here
        onCloseForm();
      }
    } catch (err) {
      console.log({ err });
    } finally {
      // setIsLoading(false);
    }
  }, [value, authUser, onCloseForm]);

  const handleUpdatePreview = React.useCallback(() => {
    setValue({ ...value, content: JSON.parse(tempContent) });
  }, [tempContent, value]);

  const handleCheckboxChange = (event) => {
    const equipmentName = event.target.name;
    const checked = event.target.checked;

    const equipment = value.equipment.split(",");
    let newEquipment = null;
    if (checked) {
      newEquipment = [...equipment, equipmentName];
    } else {
      newEquipment = equipment.filter((x) => x !== equipmentName);
    }
    setValue({ ...value, equipment: newEquipment.join(",") });
  };

  const handleAddSection = React.useCallback(() => {
    const parsedTempContent = JSON.parse(tempContent);
    parsedTempContent.push({
      title: sectionTitle,
      content: [],
    });
    setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
    setValue({ ...value, content: parsedTempContent });
    setShowAddSection(false);
    setSectionTitle("");
  }, [sectionTitle, tempContent, value]);

  const handleAddImage = React.useCallback(() => {
    const imgHref = prompt("Image URL (ie: /img/something.jpg):");
    if (imgHref) {
      const parsedTempContent = JSON.parse(tempContent);
      parsedTempContent[parsedTempContent.length - 1].content.push({
        type: "image",
        value: imgHref,
      });

      setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
      setValue({ ...value, content: parsedTempContent });
    }
  }, [tempContent, value]);

  const handleAddLink = React.useCallback(() => {
    const href = linkHref;
    // add exercise to latest section
    // reset show add form
    const parsedTempContent = JSON.parse(tempContent);
    parsedTempContent[parsedTempContent.length - 1].content.push({
      type: "link",
      value: { href, title: linkText },
    });
    setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
    setValue({ ...value, content: parsedTempContent });
    setShowAddLink(false);
    setLinkText("");
    setLinkHref("");
  }, [tempContent, value, linkText, linkHref]);

  const handleAddText = React.useCallback(() => {
    const text = prompt("Enter text:");
    if (text) {
      const parsedTempContent = JSON.parse(tempContent);
      parsedTempContent[parsedTempContent.length - 1].content.push({
        type: "text",
        value: text,
      });
      setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
      setValue({ ...value, content: parsedTempContent });
    }
  }, [tempContent, value]);

  const handleAddBullets = React.useCallback(() => {
    const text = prompt("Enter bullets (semicolon;separated;like;this):");
    if (text) {
      const parsedTempContent = JSON.parse(tempContent);
      parsedTempContent[parsedTempContent.length - 1].content.push({
        type: "bullets",
        value: text
          .split(";")
          .map((x) => x.trim())
          .filter((x) => x.length > 0),
      });
      setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
      setValue({ ...value, content: parsedTempContent });
    }
  }, [tempContent, value]);

  const handleAddExercise = React.useCallback(
    (e) => {
      const slug = selectedExercise;

      if (exerciseReps && exerciseTimer) {
        alert("Cannot have both reps and timer fields set");
        return;
      } else if (exerciseReps && exerciseRest) {
        alert("Cannot set rest period when reps field is set");
      } else if (exerciseReps !== "" || exerciseTimer !== "") {
        const thingToSplit = exerciseReps || exerciseTimer;
        const splitValues = thingToSplit.split(",");
        let invalid = false;
        splitValues.every((rep) => {
          if (isNaN(rep)) {
            invalid = true;
          }
          return true;
        });
        if (invalid) {
          alert("Invalid number for rep or timer");
          return;
        }
      }
      if (selectedExercise === "none") {
        alert("Pick an exercise");
        return;
      }
      // add exercise to latest section
      // reset show add form
      const parsedTempContent = JSON.parse(tempContent);
      parsedTempContent[parsedTempContent.length - 1].content.push({
        type: "exercise",
        value: {
          slug,
          text: exerciseTitle,
          reps:
            exerciseReps === ""
              ? []
              : exerciseReps.split(",").map((x) => Number(x)),
          timer:
            exerciseTimer === ""
              ? []
              : exerciseTimer.split(",").map((x) => Number(x)),
          rest:
            exerciseRest === ""
              ? []
              : exerciseRest.split(",").map((x) => Number(x)),
        },
      });
      setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
      setValue({ ...value, content: parsedTempContent });
      setSelectedExercise("none");
      setShowAddExercise(false);
      setExerciseReps("");
      setExerciseTimer("");
      setExerciseTitle("");
      setExerciseRest("");
    },
    [
      tempContent,
      value,
      selectedExercise,
      exerciseTitle,
      exerciseReps,
      exerciseTimer,
      exerciseRest,
    ]
  );

  const handleChangeTitle = React.useCallback((e) => {
    setExerciseTitle(e.target.value);
  }, []);

  const handleExerciseChange = React.useCallback(
    (e) => {
      // set the title input box to the title from the selected item
      // allow user to customize and use in handleExercise
      setSelectedExercise(e.target.value);
      const titleFind = exerciseLibrary.find((x) => x.slug === e.target.value);
      if (titleFind) {
        setExerciseTitle(titleFind.title);
      }
    },
    [exerciseLibrary]
  );

  const handleAddVideo = React.useCallback(
    (e) => {
      const href = selectedVideo === "none" ? videoText : selectedVideo;
      // add exercise to latest section
      // reset show add form
      const parsedTempContent = JSON.parse(tempContent);
      parsedTempContent[parsedTempContent.length - 1].content.push({
        type: "video",
        value: { href, title: videoText },
      });
      setTempContent(JSON.stringify(parsedTempContent, undefined, 4));
      setValue({ ...value, content: parsedTempContent });
      setSelectedVideo("none");
      setShowAddVideo(false);
      setVideoText("");
    },
    [tempContent, value, selectedVideo, videoText]
  );

  const handleChangeVideoText = React.useCallback((e) => {
    setVideoText(e.target.value);
  }, []);

  const handleChangeLinkText = React.useCallback((e) => {
    setLinkText(e.target.value);
  }, []);

  const handleChangeSectionTitle = React.useCallback((e) => {
    setSectionTitle(e.target.value);
  }, []);

  const handleChangeExerciseReps = React.useCallback((e) => {
    setExerciseReps(e.target.value);
  }, []);

  const handleChangeExerciseTimer = React.useCallback((e) => {
    setExerciseTimer(e.target.value);
  }, []);

  const handleChangeExerciseRest = React.useCallback((e) => {
    setExerciseRest(e.target.value);
  }, []);

  const handleChangeLinkHref = React.useCallback((e) => {
    setLinkHref(e.target.value);
  }, []);

  const handleVideoChange = React.useCallback(
    (e) => {
      // set the title input box to the title from the selected item
      // allow user to customize and use in handleExercise
      setSelectedVideo(e.target.value);
      const titleFind = videoCollection.find((x) => x.slug === e.target.value);
      if (titleFind) {
        setVideoText(titleFind.title);
      }
    },
    [videoCollection]
  );

  const week = initialState ? value.week : "";
  const day = initialState ? value.day : "";
  const title = initialState ? value.title : "";
  const equipment = initialState ? value.equipment : "";
  const video_title = initialState ? value.video_title : "";
  const video_url = initialState ? value.video_url : "";

  return (
    <div>
      <div>
        <div>
          <label htmlFor="week">
            <strong>Week (2021-09-26 format)</strong>
          </label>

          <input
            name="week"
            value={week}
            onChange={handleChange}
            placeholder="Week"
            type="text"
          />
        </div>
        <div>
          <label htmlFor="day">
            <strong>Day (1, 2, 3, 4 or 5)</strong>
          </label>
          <input
            name="day"
            value={day}
            onChange={handleChange}
            placeholder="Day"
          />
        </div>
        <div>
          <label htmlFor="title">
            <strong>Title</strong>
          </label>
          <input
            name="title"
            value={title}
            onChange={handleChange}
            placeholder="Title"
          />
        </div>
        <div>
          <label htmlFor="equipment">
            <strong>Equipment</strong>
          </label>
          <div>
            <div>
              <input
                style={{ marginRight: "5px" }}
                name="dumbbell"
                id="dumbbell"
                type="checkbox"
                checked={equipment.indexOf("dumbbell") > -1}
                onChange={handleCheckboxChange}
              />
              Dumbbell
            </div>
            <div>
              <input
                style={{ marginRight: "5px" }}
                name="chair"
                id="chair"
                type="checkbox"
                checked={equipment.indexOf("chair") > -1}
                onChange={handleCheckboxChange}
              />
              Chair
            </div>
            <div>
              <input
                style={{ marginRight: "5px" }}
                name="step"
                id="step"
                type="checkbox"
                checked={equipment.indexOf("step") > -1}
                onChange={handleCheckboxChange}
              />
              Steps
            </div>
            <div>
              <input
                style={{ marginRight: "5px" }}
                name="bench"
                id="bench"
                type="checkbox"
                checked={equipment.indexOf("bench") > -1}
                onChange={handleCheckboxChange}
              />
              Bench
            </div>
          </div>
        </div>
      </div>
      <div>
        <button onClick={() => setShowAddSection(!showAddSection)}>
          {showAddSection ? "Cancel" : "Add Section"}
        </button>
        <button onClick={handleAddText}>Add Text</button>
        <button onClick={() => setShowAddExercise(!showAddExercise)}>
          {showAddExercise ? "Cancel" : "Add Exercise"}
        </button>
        <button onClick={() => setShowAddVideo(!showAddVideo)}>
          {showAddVideo ? "Cancel" : "Add Video"}
        </button>
        <button onClick={handleAddBullets}>Add Bullets</button>
        <button onClick={handleAddImage}>Add Image</button>
        <button onClick={() => setShowAddLink(!showAddLink)}>
          {showAddLink ? "Cancel" : "Add Link"}
        </button>
      </div>
      {showAddExercise && (
        <div>
          <select
            onChange={handleExerciseChange}
            onBlur={() => true}
            value={selectedExercise}
            style={{ width: "100%" }}
          >
            <option key={`exercise-none`} value={"none"}>
              Select exercise...
            </option>
            {EXERCISE_OPTIONS.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <span>Exercise display name</span>
          <input
            name="exerciseTitle"
            value={exerciseTitle}
            onChange={handleChangeTitle}
            onBlur={handleChangeTitle}
          />
          <span>Reps per round, comma separated</span>
          <input
            name="exerciseReps"
            value={exerciseReps}
            onChange={handleChangeExerciseReps}
            onBlur={handleChangeExerciseReps}
            placeholder="10,15,20"
          />{" "}
          <span>Time per round in seconds, comma separated</span>
          <input
            name="exerciseTimer"
            value={exerciseTimer}
            onChange={handleChangeExerciseTimer}
            onBlur={handleChangeExerciseTimer}
            placeholder="30,45,60"
          />
          <span>Rest period per timed round, HIIT/tabatas</span>
          <input
            name="exerciseRest"
            value={exerciseRest}
            onChange={handleChangeExerciseRest}
            onBlur={handleChangeExerciseRest}
            placeholder="10,10,10"
          />
          <button onClick={handleAddExercise}>OK</button>
        </div>
      )}
      {showAddVideo && (
        <div>
          <select
            onChange={handleVideoChange}
            onBlur={() => true}
            value={selectedVideo}
            style={{ width: "100%" }}
          >
            <option key={`exercise-none`} value={"none"}>
              Select video...
            </option>
            {VIDEO_OPTIONS.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          <input
            name="videoText"
            value={videoText}
            onChange={handleChangeVideoText}
            onBlur={handleChangeVideoText}
          />
          <button onClick={handleAddVideo}>OK</button>
        </div>
      )}
      {showAddLink && (
        <div>
          <input
            name="linkText"
            value={linkText}
            onChange={handleChangeLinkText}
            onBlur={handleChangeLinkText}
            placeholder="Link Text"
          />
          <input
            name="linkHref"
            value={linkHref}
            onChange={handleChangeLinkHref}
            onBlur={handleChangeLinkHref}
            placeholder="Link URL"
          />
          <button onClick={handleAddLink}>OK</button>
        </div>
      )}
      {showAddSection && (
        <div>
          <input
            name="sectionTitle"
            value={sectionTitle}
            onChange={handleChangeSectionTitle}
            onBlur={handleChangeSectionTitle}
            placeholder="Section Title"
          />
          <button onClick={handleAddSection}>OK</button>
        </div>
      )}
      <label htmlFor="textarea">
        <strong>Contents</strong>
      </label>
      <div>
        <textarea
          id="content"
          name="content"
          value={tempContent}
          onChange={handleChange}
          rows={20}
          cols={60}
        />
      </div>
      <div>
        <label htmlFor="video_title">
          <strong>Video Title</strong>
        </label>
        <input
          name="video_title"
          value={video_title}
          onChange={handleChange}
          placeholder="Video Title"
        />
      </div>
      <div>
        <label htmlFor="video_url">
          <strong>Video URL</strong>
        </label>
        <input
          name="video_url"
          value={video_url}
          onChange={handleChange}
          placeholder="Video URL"
        />
      </div>
      <div>
        <button
          className="fancy"
          onClick={handleUpdatePreview}
          disabled={isLoading}
        >
          update preview
        </button>
        <button
          className="fancy"
          onClick={postWorkoutPayload}
          disabled={isLoading}
        >
          {isLoading ? "saving..." : "save"}
        </button>
      </div>
    </div>
  );
};

export default WorkoutForm;
