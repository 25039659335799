import * as React from "react";
import WorkoutDay from "../../components/WeeklyWorkout/WorkoutDay";
import WorkoutDetail from "../../components/WeeklyWorkout/WorkoutDetail";

const WorkoutPreview = ({ data, handleEdit }) => {
  if (!data) return <span>Loading...</span>;

  return (
    <div>
      <div>
        <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
          Preview
        </h2>
      </div>
      <div>
        <h3 className="title is-size-3 has-text-weight-bold is-bold-light">
          Tile Preview
        </h3>
        <div className="weekly-workout-container">
          <div className="weekly-workout-days">
            <WorkoutDay
              title={data.title}
              number={data.day}
              equipment={data.equipment}
            />
          </div>
        </div>
      </div>
      <div>
        <h3 className="title is-size-3 has-text-weight-bold is-bold-light">
          Workout Preview
        </h3>
        <div className="weekly-workout-container">
          <WorkoutDetail {...data} userData={[]} isPreview handleEdit={handleEdit} />
        </div>
      </div>
    </div>
  );
};

export default WorkoutPreview;
