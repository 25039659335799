import React from "react";
import { compose } from "recompose";
import Layout from "../../components/Layout";
import {
  useAuthUser,
  withAuthorization,
  withEmailVerification,
} from "../../components/Session";
import WorkoutForm from "../../components/WeeklyWorkout/WorkoutForm";
import WorkoutPreview from "../../components/WeeklyWorkout/WorkoutPreview";
import * as ROLES from "../../constants/roles";

function moveItem(arr, itemIndex, targetIndex) {
  let itemRemoved = arr.splice(itemIndex, 1); // splice() returns the remove element as an array
  arr.splice(targetIndex, 0, itemRemoved[0]); // Insert itemRemoved into the target index
  return arr;
}

function removeItem(arr, index) {
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

const WorkoutList = ({ data, onSelection }) => (
  <div>
    <ul>
      {data.map((item, index) => (
        <li key={`workout-list-item-${item.week}-${item.day}-${index}`}>
          {item.week}, Day #{item.day} - {item.title}{" "}
          <button onClick={() => onSelection(item)}>edit</button>
        </li>
      ))}
    </ul>
  </div>
);

function WorkoutContentPageBase() {
  const [isClient, setClient] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState();
  const [currentContent, setCurrentContent] = React.useState(null);
  const [listData, setListData] = React.useState([]);
  const authUser = useAuthUser();

  React.useEffect(() => {
    setClient(true);
  }, []);

  React.useEffect(() => {
    if (!showForm) {
      setSelectedItem();
    }
  }, [showForm]);

  React.useEffect(() => {
    setCurrentContent(selectedItem);
  }, [selectedItem]);

  const handleSelection = React.useCallback((item) => {
    setSelectedItem(item);
    setShowForm(true);
  }, []);

  const handleCloseForm = React.useCallback(() => {
    setShowForm(false);
  }, []);

  const handleContentChange = React.useCallback((data) => {
    setCurrentContent(data);
  }, []);

  const handleEdit = React.useCallback(
    ({ fn, sectionIndex, itemIndex }) => {
      if (fn === "up" || fn === "down") {
        const indexToUse = itemIndex < 0 ? sectionIndex : itemIndex;
        const position = fn === "up" ? indexToUse - 1 : indexToUse + 1;
        if (itemIndex < 0) {
          // move section
          const newContent = moveItem(
            currentContent.content,
            sectionIndex,
            position
          );
          const updated = { ...currentContent, content: newContent };

          setCurrentContent(updated);
        } else {
          const newSubContent = moveItem(
            currentContent.content[sectionIndex].content,
            itemIndex,
            position
          );
          const newContent = [...currentContent.content];
          newContent[sectionIndex].content = newSubContent;
          const updated = { ...currentContent, content: newContent };
          setCurrentContent(updated);
        }
      } else if (fn === "delete") {
        // eslint-disable-next-line no-restricted-globals
        const confirmDelete = window.confirm("Are you sure?");
        if (confirmDelete) {
          if (itemIndex < 0) {
            // entire section delete
            const newContent = removeItem(currentContent.content, sectionIndex);
            const updated = { ...currentContent, content: newContent };
            setCurrentContent(updated);
          } else {
            // node item delete
            const newSubContent = removeItem(
              currentContent.content[sectionIndex].content,
              itemIndex
            );
            const newContent = [...currentContent.content];
            newContent[sectionIndex].content = newSubContent;
            const updated = { ...currentContent, content: newContent };
            setCurrentContent(updated);
          }
        }
      }
    },
    [currentContent]
  );

  const fetchWorkoutData = React.useCallback(async () => {
    try {
      const result = await fetch("/.netlify/functions/get-workouts-for-week", {
        method: "POST",
        body: JSON.stringify({
          week: "all",
          uid: authUser.uid,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      const json = await result.json();
      if (json.status === "succeeded") {
        setListData(
          json.data.map((x) => ({ ...x, content: JSON.parse(x.content) }))
        );
      }
    } catch (err) {
      console.log({ err });
    }
  }, [authUser]);

  React.useEffect(() => {
    if (isClient) {
      fetchWorkoutData();
    }
  }, [isClient, fetchWorkoutData]);

  if (!isClient) {
    return <span>Loading...</span>;
  }

  return (
    <div className="columns">
      <div className="column is-12">
        <h1 className="title is-size-1 has-text-weight-bold is-bold-light">
          Workout Content
        </h1>
        <button
          className="fancy"
          onClick={() => {
            setShowForm(!showForm);
          }}
        >
          {!showForm ? "Add Workout" : "Cancel"}
        </button>
        <div className="columns">
          <div className="column is-6">
            {showForm && (
              <WorkoutForm
                initialState={selectedItem}
                onContentChange={handleContentChange}
                onCloseForm={handleCloseForm}
              />
            )}
            <WorkoutList data={listData} onSelection={handleSelection} />
          </div>
          <div className="column is-6">
            {showForm && (
              <WorkoutPreview data={currentContent} handleEdit={handleEdit} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const condition = (authUser) => authUser && !!authUser.roles[ROLES.ADMIN];

const WorkoutContentPage = compose(
  withEmailVerification,
  withAuthorization(condition)
)(WorkoutContentPageBase);

const WorkoutContentPageFinal = () => (
  <Layout>
    <WorkoutContentPage />
  </Layout>
);

export default WorkoutContentPageFinal;
