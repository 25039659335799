import { graphql, useStaticQuery } from "gatsby";

const useWarmupStretchVideos = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query WARMUP_STRETCH_VIDEO_TITLES {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___title] }
          filter: {
            frontmatter: {
              templateKey: { eq: "training-video-post" }
              tags: { in: ["Warm-up", "Stretch"] }
            }
          }
        ) {
          edges {
            node {
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  return allMarkdownRemark.edges
    .map((x) => ({ title: x.node.frontmatter.title, slug: x.node.fields.slug }))
    .sort(function(a, b) {
      return a.title.localeCompare(b.title, undefined, {
        ignorePunctuation: true,
      });
    });
};

export default useWarmupStretchVideos;
